import React from 'react';
import { Image, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import './BofArticle.scss';
import translations from '../translations';

function openUrl(prefix) {
  let url = window.location.href;
  if (url.indexOf('?') !== -1) {
    url = url.split('?')[0];
  }
  url = url.replace('#', '?path=');
  window.open(prefix + url);
}

export default class BofArticle extends React.Component {
  render() {
    const { children, title, content, image, provider } = this.props;
    return (
      <div className="article bof--article">
        {title && title !== 'null' && <h3>{title}</h3>}
        <p className="bof--image">
          {image && <Image src={image} responsive />}
        </p>
        <p className="articleProvider">{provider}</p>
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: content }}
        />
        {children &&
          children.length && (
          <div className="additionalColumns">{children}</div>
        )}
        <hr />
        <p>
          <Button
            bsClass="btn-link"
            onClick={() => openUrl('https://www.facebook.com/sharer.php?u=')}
          >
            <span className="socicon-facebook" /> {translations[window.BofConfig.globalLang]['Adds']['shareOnFacebook']}
          </Button>
        </p>
      </div>
    );
  }
}

BofArticle.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  content: PropTypes.string,
  image: PropTypes.string,
  provider: PropTypes.string
};
