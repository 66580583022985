import React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import {
  ArticleWindow,
  ArticleComments,
  ensureArticleModuleConfig
} from 'react-article-module';
import PropTypes from 'prop-types';
import BofArticle from '../components/BofArticle';
import Title from '../components/Title';
import WmsLegends from '../components/WmsLegends';
import LegendBtns from '../components/LegendBtns';
import './ArticleDetailView.scss';
import translations from '../translations';

const mapStateToProps = state => ({
  activeSources: state.articles.activeSources
});
export class ArticleDetailView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLegends: false
    };
  }

  render() {
    const {
      params: { pageId, splat },
      activeSources,
      enableArticleComments
    } = this.props;
    const { showLegends } = this.state;
    const pushPath = path => browserHistory.push('/' + path);

    return (
      <div className="article-detail-layout--root">
        <div className="article-detail-layout--components">
          <div className="article-detail-view--container">
            <Title title={translations[window.BofConfig.globalLang]['Adds']['pageTitle']} />
            <div className="article-detail-view--buttonbar">
              <LegendBtns
                showLegends={showLegends}
                onShowLegendsClick={showLegends =>
                  this.setState({ showLegends })
                }
              />
            </div>
            {showLegends && (
              <WmsLegends
                urls={[
                  'http://gridw.geopanel.eu/WebServices/generic/GetCustomLegend.ashx?F=ff58e74712eb2b7a419802009df1ca5058196214.png'
                  //,
                  //'http://wms.gridw.geopanel.eu/qgis.ashx?map=6b26bb62-b800-4ed2-9fed-8d3edd7089c8&LAYERS=Szlaki piesze&FORMAT=image/png&VERSION=1.1.1&SERVICE=WMS&REQUEST=GetLegendGraphic&LAYERTITLE=true&ITEMFONTSIZE=9&LAYERFONTSIZE=11'
                ]}
              />
            )}
            <div className={showLegends ? 'article-detail-view--hidden' : ''}>
              <ArticleWindow
                articleComponent={BofArticle}
                id={pageId + '_' + splat}
                onClose={() => {
                  let path = 'mapa';
                  if (activeSources) {
                    path += '/' + activeSources;
                  }
                  pushPath(path);
                }}
                maxZoom={16}
                hideOpenExternalBtn
              />
            </div>
            {enableArticleComments /* TODO: Cannot use pageId for comments, must use article globalId */ && (
              <ArticleComments id={pageId} hasForm />
            )}
          </div>
        </div>
      </div>
    );
  }
}

ArticleDetailView.propTypes = {
  params: PropTypes.object.isRequired,
  activeSources: PropTypes.string,
  enableArticleComments: PropTypes.bool
};

export default connect(mapStateToProps)(
  ensureArticleModuleConfig(ArticleDetailView)
);
