import createStore from './createStore';
import { injectReducer } from './reducers';

const initialState = window.___INITIAL_STATE__;
const store = createStore(initialState);

export function addReducerToStore(key, reducer) {
  injectReducer(store, { key, reducer });
}

export default store;